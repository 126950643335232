@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20px;
    letter-spacing: 2px;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}
.btn--primary2 {
    background-color: #242424;
    color: #fff;
    border: 1px solid #242424;
}
.btn--outline {
    background-color:  transparent;
    color: #fff;
    padding: 8px 10px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px; 
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--larger {
    padding: 12px 26px;
    font-size: 30px;
}

.btn--medium:hover, .btn--large:hover {
    background-color: #fff;
    color: #443b31;
    transition: all 0.3 ease-out;
}
.btn--primary:hover {
    background-color: #443b31;
    color: #fff;
    transition: all 0.3 ease-out;
}