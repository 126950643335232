@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}
.contact-us{
    min-height: 100vh;
    background: url(../../public/images/Zinolwandle-projects-under-construction.jpg) no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

}

.contact-section{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.contact-info{
    color: #fff;
    max-width: 500px;
    line-height: 65px;
    padding-left: 50px;
    font-size: 18px;
}

.contact-info i {
    margin-right: 20px;
    font-size: 25px;
}

.contact-form{
    max-width: 700px;
    margin-right: 50px;

}

.contact-info, .contact-form{
    flex: 1;
}

.contact-form h2{
    color: #fff;
    text-align: center;
    font-size: 35px;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.contact-form .text-box{
    background: #fff;
    color: #000;
    border: none;
    width: calc(50% - 10px);
    height: 50px;
    padding: 12px;
    font-size: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    opacity: 0.9;
}
.contact-form .text-box:first-child{
    margin-right: 15px;
}

.contact-form textarea{
    background: #fff;
    color: #000;
    border: none;
    width: 100%;
    padding: 12px;
    min-height: 200px;
    max-height: 400px;
    resize: vertical;
    font-size: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    opacity: 0.9;    
}

.contact-form .send-btn{
    float: right;
    background: #443b31;
    color: #fff;
    border: none;
    width: 120px;
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 5px;
    transition: 0.3s;
    transition-property: background;
    cursor: pointer;
}

.contact-form .send-btn:hover{
    /* background-color:  #fff;
    color: #443b31; */
    background-color:  #ba831e;
    color: #000;
    
}

@media screen and (max-width: 950px){
    .contact-section{
        flex-direction: column;
    }
    .contact-info .contact-form{
        margin: 30px 50px;
    }
    /* To shift left to bottom */
    .contact-info{
        order: 1;
        float: left;
    }   
    .contact-form{
        margin-left: 50px;
    }

    .contact-form h2 {
        font-size: 30px;
    }
    .contact-form .text-box{
        width: 100%;
    }
}

/*CSS For alert Message*/
.alert-success{
    z-index: 1;
    background: #D4EDDA;
    font-size: 18px;
    padding: 20px 40px;
    min-width: 420px;
    position: fixed;
    top: 10px;
    right: 0px;
    border-left: 8px solid #3AD66E;
    border-radius: 2px;
}

.alert-failure{
    z-index: 1;
    background: #f17878;
    font-size: 18px;
    padding: 20px 40px;
    min-width: 420px;
    position: fixed;
    top: 120px;
    right: 0px;
    border-left: 8px solid #f02424;
    border-radius: 2px;
}
    /* Style for the clickable phone number link */
a.phone-link {
    color: inherit; /* Use the default text color */
    text-decoration: none; /* Remove underline */
}

    /* Style for the link when it is clicked or focused */
a.phone-link:focus,
a.phone-link:active {
    outline: none; /* Remove the default focus outline */
    border: none; /* Remove any border */
    box-shadow: none; /* Remove any box shadow */
}
a.phone-link:hover{
    color:  #ba831e; 
}

a.phone-link-2 {
    color: inherit; /* Use the default text color */
    text-decoration: none; /* Remove underline */
}

    /* Style for the link when it is clicked or focused */
a.phone-link-2:focus,
a.phone-link-2:active {
    outline: none; /* Remove the default focus outline */
    border: none; /* Remove any border */
    box-shadow: none; /* Remove any box shadow */
}
a.phone-link-2:hover{
    color:  #ba831e; 
}

a.email-link {
    color: inherit; /* Use the default text color */
    text-decoration: none; /* Remove underline */
}

    /* Style for the link when it is clicked or focused */
a.email-link:focus,
a.email-link:active {
    outline: none; /* Remove the default focus outline */
    border: none; /* Remove any border */
    box-shadow: none; /* Remove any box shadow */
}
a.email-link:hover{
    color:  #ba831e; 
}