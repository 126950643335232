@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.home,
.services,
.projects {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('../public/images/Zinolwandle-projects-under-construction.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
  color: #fff;
  font-size: 100px;
}

.projects {
  background-image: url('../public/images/Zinolwandle-projects-under-construction-1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #443b31;
  font-size: 100px;
}

/* .contact-us {
  background-image: url('../public/images/Zinolwandle-projects-under-construction.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
} */